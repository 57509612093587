<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    item-text="name"
    item-value="id"
    :search-input.sync="search"
    :label="isMultiple ? $tc('products.selectProduct.label', 2) : $tc('products.selectProduct.label', 1)"
    :multiple="isMultiple"
    :disabled="hasDisabledInput"
    :clearable="isClearable"
    outlined
    hide-details
    :return-object="returnObject"
    :rules="isRequired ? requiredRules : []"
    :validate-on-blur="isRequired"
    @input="onInput">
    <template v-slot:selection="{item}">
      <span>{{ item.name }}</span>
    </template>

    <template v-slot:item="{item}">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import formRules from "@/mixins/formRules";

export default {
  name: "SelectProduct",

  mixins: [formRules],

  props: {
    isRequired: {type: Boolean, default: false},
    isClearable: {type: Boolean, default: true},
    isMultiple: {type: Boolean, default: false},
    hasDisabledInput: {type: Boolean, default: false},
    accountId: {type: String, default: null},
    value: {type: String|Object, default: null},
    returnObject: {type: Boolean, default: true}
  },

  components: {
  },

  data() {
    return {
      items: [],
      isLoading: false,
      model: null,
      search: null,
      timeId: null,
      timerCount: 500
    }
  },

  created() {
    this.getItems();
  },

  methods: {
    getItems() {
      this.isLoading = true;
      this.$http
        .get(`/products?accounts[]=${this.accountId}&search=${this.search}`, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.items = res.data.data;
          this.model = this.value;

          if (this.model) {
            // is Array
            if (Array.isArray(this.model)) {
              this.items.push(
                ...this.model.filter((el) => this.items.findIndex(el2 => el2.id === el.id) === -1));
            }
            else {
              if (this.items.findIndex(el2 => el2.id === this.model.id) === -1)
                this.items.push(this.model)
            }
          }
        })
        .catch((err) => {

        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    onInput(val) {
      this.$emit("input", val);
      this.search = "";
    }
  },
  watch: {
    search(val) {
      const modelStr = this.model && typeof this.model === "object" && this.model["name"] ? this.model.name : this.model;
      if (val && val !== modelStr && !this.isLoading) {
        this.isLoading = true;
        this.timerId = setTimeout(() => {
          this.getItems();
        }, this.timerCount);
      }
    },

    accountId() {
      this.getItems();
    }
  }
}
</script>

<style scoped>

</style>
